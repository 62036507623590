<div class="d-flex justify-content-start align-items-center">
    <app-date-picker class="date-picker"
        (dateChanged)="handleDateChange($event.fromDate, $event.toDate)"></app-date-picker>
    <div class="ml-5 col">Grid date: {{ dateFrom.toString() | date: 'yyyy-MM-dd HH:mm:ss zzz' }} to {{ dateTo.toString()
        | date: 'yyyy-MM-dd HH:mm:ss zzz' }}</div>
</div>

<form [formGroup]="radioForm" class="radio-group">
    <ng-container *ngFor="let option of options">
        <div class="radio-option">
            <input type="radio" 
                    class="btn-check" 
                    [id]="option.id" 
                    [value]="option.id"
                    formControlName="selectedOption"
                    autocomplete="off">
            <label class="btn btn-outline-primary" [for]="option.id">{{ option.label }}</label>
        </div>
    </ng-container>
</form>

<div class="row mt-3" *ngIf="radioForm.get('selectedOption').value === 'option4'">
  <div class="col">
    <div class="btn-group ms-2 keyword-filter-container" role="group">
      <button *ngFor="let filter of keywordFilters"
              type="button"
              class="btn btn-outline-secondary"
              [class.active]="selectedKeywordFilter === filter.id"
              (click)="applyKeywordFilter(filter.id)">
        {{ filter.label }}
      </button>
    </div>
  </div>
</div>

<app-filing-grid-display #grid class="grid-height" [rowData]="rowData"></app-filing-grid-display>